import { Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import i18n from '../../i18n';
import { useLanguage } from '../../context/LanguageContext';


const LanguageSwitcher = () => {
    // Print only language code if breakpoint is < small
    const theme = useTheme();
    const breakpointSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const { language, setLanguage } = useLanguage();

    const languageList = [
        { label: i18n.t('lang.en'), code: 'en' },
        { label: i18n.t('lang.fi'), code: 'fi' },
        { label: i18n.t('lang.sv'), code: 'sv' },
    ];

    return (
        <Stack
            direction='row'
            spacing={{ xs: 0, sm: 1 }}
            justifyContent={{ xs: 'flex-end' }}
        >
            {languageList.map((lng, index) => (
                <Button
                    key={index}
                    onClick={() => {
                        setLanguage(lng.code);
                        localStorage.setItem('language', lng.code);
                        i18n.changeLanguage(lng.code);
                    }}
                    disableRipple
                    sx={{
                        minWidth: 'auto',
                        //m: '0',
                        //py: '0',
                        //px: { xs: '0.3rem', sm: '0.6rem' },
                        color: 'black',
                        fontSize: '0.9rem',
                        fontWeight: '400',
                        textDecoration: language === lng.code ? 'underline' : 'none',
                        textTransform: { xs: 'uppercase', sm: 'none' },
                        '&:hover': {
                            bgcolor: 'transparent',
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {breakpointSmall ? lng.label : lng.code}
                </Button>
            ))}
        </Stack>
    )
}

export default LanguageSwitcher;