import { useTranslation } from 'react-i18next';
import { Box, Icon } from '@mui/material';


const Loading = () => {
    const { t } = useTranslation();

    return (
        <Box
            //alt={t('logoAlt')}
            sx={{
                mr: { xs: '0.5rem', md: '2rem' }
            }}
        >
            <Icon
                className='fas fa-spinner'
            //sx={{ mr: '0.5rem', verticalAlign: 'top', fontSize: 'inherit' }}
            />
        </Box>
    );
}

export default Loading;