import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <LanguageProvider>
            <AuthContextProvider>
                    <App />
            </AuthContextProvider>
        </LanguageProvider>
    </Router>
);
