import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { AppBar, Box, Divider, Drawer, IconButton, Link, List, Stack, Toolbar, Typography } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import Logo from './Logo';
import LanguageSwitcher from './LanguageSwitcher';
import { UserNavButton, UserNavLink } from './NavItemsUser';
import { DrawerNavButton, DrawerNavLink } from './NavItemsDrawer';
import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';


const Header = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false);

    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const logout = useLogout();
    //const [loading, setLoading] = useState(false);

    const onLogout = async (e) => {
        //setLoading(true);
        await logout();
        navigate('/');
        //setLoading(false);
    }

    ///////// MENU ITEMS

    const navMain = [
        { icon: <HomeIcon />, label: t('navLink.home'), type: 'link', action: '/' },
        { icon: <QuestionAnswerIcon />, label: t('navLink.panels'), type: 'link', action: '/panels/' },
        { icon: <InfoIcon />, label: t('navLink.infopage'), type: 'link', action: '/info/' },
    ];

    const navUser = [
        { icon: <LoginIcon />, label: t('navLink.login'), type: 'link', action: '/auth/login/', authenticated: false },
        { icon: <PersonIcon />, label: t('navLink.profile'), type: 'link', action: '/user/', authenticated: true },
        { icon: <LogoutIcon />, label: t('navLink.logout'), type: 'button', action: onLogout, authenticated: true },
    ];

    ///////// MAIN MENU

    const mainMenu = (
        <Stack spacing={2} direction='row' sx={{ fontWeight: '700' }}>
            {navMain.map((item, index) => (
                <Typography key={index} variant='div' fontSize='1.5rem'>
                    <Link
                        href={item.action}
                        color='investDarkGrey'
                        fontSize='1.1rem'
                        underline='none'
                        sx={{
                            color: location.pathname === item.action ? 'black' : 'investDarkGrey',
                            ':after': {
                                content: '""',
                                display: 'block',
                                margin: '0 auto',
                                width: '80%',
                                paddingTop: '8px',
                                borderBottom: '3px solid',
                                borderColor: location.pathname === item.action ? 'black' : 'transparent',
                            },
                            '&:hover': {
                                color: 'black',
                                ':after': { borderColor: 'black' }
                            },
                        }}
                    >
                        {item.label}
                    </Link>
                </Typography>
            ))}
        </Stack>
    );

    ///////// USER MENU

    const userMenu = (
        <Box sx={{
            ml: '1.5rem',
            p: '0.75rem 1rem',
            borderLeft: '1px solid #ddd',
        }}>
            {navUser.map((item, index) => {
                if (item.authenticated === isLoggedIn) {
                    if (item.type === 'button')
                        return (
                            <UserNavButton
                                key={index}
                                action={item.action}
                                icon={item.icon}
                                label={item.label}
                            />
                        );
                    else
                        return (
                            <UserNavLink
                                key={index}
                                action={item.action}
                                icon={item.icon}
                                label={item.label}
                            />
                        );
                }
            })}
        </Box >
    );

    ///////// DRAWER

    const toggleDrawer = () => setOpenDrawer(!openDrawer);

    const DrawerNav = styled(List)({
        '& .MuiListItemButton-root': {
            paddingLeft: 24,
            paddingRight: 24,
        },
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 16,
        },
        '& .MuiSvgIcon-root': {
            fontSize: 20,
        },
    });

    const listItemButtonStyles = {
        py: '0.35rem',
        color: 'investDarkGrey',
        '&:hover': {
            bgcolor: 'transparent',
            color: 'black'
        }
    }

    const drawerList = (
        <Box>
            <DrawerNav component='nav' disablePadding sx={{ py: '0.5rem' }}>
                {navMain.map((item, index) => (
                    <DrawerNavLink
                        action={item.action}
                        icon={item.icon}
                        label={item.label}
                        styles={listItemButtonStyles}
                        key={index}
                    />
                ))}
                <Divider sx={{ my: '0.5rem' }} />
                {navUser.map((item, index) => {
                    if (item.authenticated === isLoggedIn) {
                        if (item.type === 'button')
                            return (
                                <DrawerNavButton
                                    key={index}
                                    action={item.action}
                                    icon={item.icon}
                                    label={item.label}
                                    styles={listItemButtonStyles}
                                />
                            );
                        else
                            return (
                                <DrawerNavLink
                                    key={index}
                                    action={item.action}
                                    icon={item.icon}
                                    label={item.label}
                                    styles={listItemButtonStyles}
                                />
                            );
                    }
                })}
            </DrawerNav>
        </Box>
    );

    ///////// APPBAR

    return (
        <>
            <AppBar component='nav' position='sticky'>
                <Toolbar sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: { xs: '0 1rem', md: '0 0 0 2rem' },
                }}>
                    <Box>
                        <Logo />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 2 }} >
                        {mainMenu}
                    </Box>
                    <Box sx={{ flexGrow: { xs: 2, md: 0 } }}>
                        <LanguageSwitcher />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {userMenu}
                    </Box>
                    <IconButton
                        onClick={toggleDrawer}
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            ml: '1rem',
                            color: 'investDarkGrey',
                            '&:hover': {
                                bgcolor: 'transparent',
                                color: 'black',
                            },
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor='top' open={openDrawer} onClose={toggleDrawer}>
                {drawerList}
            </Drawer>
        </>
    );
}

export default Header;