import { useTranslation } from 'react-i18next';
import { Box, Link } from '@mui/material';

import { LOGO } from '../../constants';


const Logo = () => {
    const { t } = useTranslation();

    return (
        <Link href='/'>
            <Box
                component='img'
                src={LOGO}
                alt={t('projectName')}
                sx={{
                    maxHeight: '65px',
                    mr: { xs: '0.5rem', md: '2rem' }
                }}
            />
        </Link>
    );
}

export default Logo;