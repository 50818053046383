import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import Loading from './components/Loading';

// Pages
const Home = lazy(() => import('./views/Home'));
const Page = lazy(() => import('./views/Page'));
const Panel = lazy(() => import('./views/Panel'));
const Section = lazy(() => import('./views/Panel/Section'));
const AllPanels = lazy(() => import('./views/AllPanels'));
const NotFound = lazy(() => import('./views/NotFound'));

// Authentication
const PersistLogin = lazy(() => import('./actions/PersistLogin'));
const AuthMiddleware = lazy(() => import('./middlewares/AuthMiddleware'));
const Login = lazy(() => import('./views/Auth/Login'));
const Register = lazy(() => import('./views/Auth/Register'));
const ResendActivation = lazy(() => import('./views/Auth/ResendActivation'));
const RequestResetPassword = lazy(() => import('./views/Auth/RequestResetPassword'));
const ResetPassword = lazy(() => import('./views/Auth/ResetPassword'));
const ActivateUser = lazy(() => import('./views/Auth/ActivateUser'));
const UserProfile = lazy(() => import('./views/Auth/UserProfile'));


export const AppRoutes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path='/' element={<PersistLogin />}>
                    <Route index element={<Home />} />
                    <Route path='info' element={<Page slug='info' />} />
                    {/*<Route path='privacy-policy' element={<Page slug='privacy-policy' />} />*/}
                    <Route path='accessibility' element={<Page slug='accessibility' />} />
                    <Route path='panels' element={<AllPanels />} />
                    <Route path='panel' element={<AuthMiddleware />}>
                        <Route path=':slug' element={<Panel />} />
                        <Route path=':slug/:sectionId' element={<Section />} />
                    </Route>
                    <Route path='auth'>
                        <Route path='login' element={<Login />} />
                        <Route path='register' element={<Register />} />
                        <Route path='activate/:token' element={<ActivateUser />} />
                        <Route path='resend-activation' element={<ResendActivation />} />
                        <Route path='reset-password' element={<RequestResetPassword />} />
                        <Route path='reset-password/:ref' element={<ResetPassword />} />
                        <Route index element={<Login />} />
                    </Route>
                    <Route path='user' element={<AuthMiddleware />}>
                        <Route index element={<UserProfile />} />
                    </Route>
                    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>
        </Suspense>
    );
}

export default AppRoutes;