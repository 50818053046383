import React, { useEffect } from 'react';
import { loadCSS } from 'fg-loadcss';

import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme';
import AppRoutes from './routes';
import Header from './components/Header';
import Footer from './components/Layout/Footer';

import './App.css';


const App = () => {

    useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v6.6.0/css/all.css',
            document.querySelector('#font-awesome-css') || document.head.firstChild,
        );

        return () => {
            node.parentNode?.removeChild(node);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <Container component='main' maxWidth='false' disableGutters sx={{ bgcolor: 'white' }}>
                <AppRoutes />
            </Container>
            <Container component='footer' maxWidth='false' disableGutters sx={{ bgcolor: 'black' }}>
                <Footer />
            </Container>
        </ThemeProvider>
    );
}

export default App;